<template>
  <div>
    <vs-alert color="warning" title="Order Review" :active.sync="order_not_delivered">
      <span>Expect review after when order is delivered. </span>
    </vs-alert >
    <vs-alert color="warning" title="No Review" :active.sync="isReview">
      <span>The buyer has not made any review </span>
    </vs-alert >
    <div v-if="showReview && review">
      <vx-card no-shadow>
        <ul class="list mb-4">
          <li class="list__item px-0">
            <div style="width: 42px;" class="mr-3">
            <vs-avatar @click="$router.push('/users/'+order.buyer.id).catch(() => {})" class="border-1 ml-0 border-solid border-white" :src="$store.state.domain+'/'+order.buyer.image" size="42px" />
            </div>

            <div class="p-3 flex-grow rounded" style="background-color:#f8f8f8;">
              <h6 class="flex font-semibold"> {{order.buyer.first_name}} {{order.buyer.last_name}} <span class="ml-2 text-warning inline-flex"><feather-icon icon="StarIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>{{review.rating}}</span></h6>
              <p>{{review.msg}}</p>
              <div> <span class="opacity-50 text-sm">{{review.created_at | date_ago}}</span>  <a class=" ml-2 mr-1 text-danger" href="javascrip:void(0)" @click.prevent="confirmDeleteRev(review.id)">Delete</a>
                <div v-if="review.responder_id" class="pl-4 pt-4">
                  <h6 class="font-semibold">Admin Response</h6>
                  <p class="mb-1"> {{review.response}} </p>
                  <small class="opacity-50">Published on {{review.responded_at | date_time}}</small>
                </div>
                <a v-if="!review.responder_id" class="ml-2 text-sm text-dark" href="javascrip:void(0)" @click.prevent="initResponse(review.id)">Respond</a>
              </div>
            </div>
          </li>
        </ul>
      </vx-card>
      <vs-popup class="holamundo" @close="close" title="Respond Review" :active.sync="popupRespondRev">
        <div class="mt-2">
          <label>You may respond to this review (This will be public)</label>
          <vs-textarea label="Share your response here" v-model="onRespond.response" rows="2" />
        </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateResponse" @click="sendResponse()" size="small">Share Response</vs-button>
      </vs-popup>
    </div>
  </div>
</template>

<script>
import StarRating from 'vue-star-rating'

export default {
  components: {
    StarRating
  },
  data() {
    return {
      showReview: false,
      order_not_delivered: false,
      awaitingDelete: null,
      popupRespondRev:false,
      onRespond: {
        id: null,
        response: "",
      },
    }
  },
  computed: {
    validateResponse() {
      return this.onRespond.response != ""
    },
    order(){
      return this.$store.state.orderList.order
    },
    review(){
      return this.order.review
    },
    isReview(){
      return this.showReview && !this.review
    }

  },
  methods: {
    initResponse(revId){
      this.onRespond.id = revId
      this.popupRespondRev = true
    },
    confirmDeleteRev(revId) {
      this.awaitingDelete = revId
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete a review permanently.' ,
        accept: this.deleteReview,
        acceptText: "Proceed",
      })
    },
    deleteReview() {
      if(this.awaitingDelete < 1){return}
      this.$http.delete(`/reviews/${this.awaitingDelete}/delete`)
      .then(response => {
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.alertSuccess(response.data.success)
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    },
    sendResponse(){
      if(this.onRespond.id < 1){return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({ response: this.onRespond.response }))
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/reviews/${this.onRespond.id}/respond`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.initializeAll()
          this.alertSuccess(response.data.success)
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=> {console.log(error)})
    },
    initializeAll(){
      this.awaitingDelete = null
      this.popupRespondRev = false
      this.onRespond = {
        id: null,
        response: "",
      }
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
  created(){
    if((this.order.status == "delivered") || (this.order.status == "completed")){
      this.showReview = true
    }else{
      this.order_not_delivered = true
    }
  }
}
</script>

<style type="text/css" >
  .vue-star-rating-star{
    height: 30px;
    width:30px;
  }
</style>
