<template>
  <div id="order-view">
    <vs-alert color="danger" title="Order Not Found" :active.sync="order_not_found">
      <span>Order record with id: '{{ $route.params.orderId }}' not found. </span>
      <span>
        <span>Check </span><span @click="$router.push('/orders').catch(() => {})" class="text-inherit underline">All Orders</span>
      </span>
    </vs-alert>

    <div v-if="!isLoading">
      <vs-alert color="primary" class="mb-4" title="Start Order!" v-if="isOrderStarted">
        <span>Note! The client is aware that order is not started. </span>
        <vs-button class="ml-4 mt-2 vs-con-loading__container" type="border" size="small" @click.prevent="markStarted()" color="primary" > Start Order Now</vs-button>
      </vs-alert>

      <vs-tabs :position="isSmallerScreen ? 'top' : 'left'" :value="activeTab" class="tabs-shadow-none" id="profile-tabs" :key="isSmallerScreen">
        <!-- GENERAL -->
        <vs-tab icon-pack="feather" icon="icon-package" :label="!isSmallerScreen ? 'Overview' : ''">
          <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
            <order-overview />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" class="activeChild" icon="icon-file-text" :label="!isSmallerScreen ? 'Requirements' : ''">
          <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
            <order-requirements />
          </div>
        </vs-tab>

        <vs-tab icon-pack="feather" icon="icon-send" :label="!isSmallerScreen ? 'Delivery Unit' : ''">
          <div class="tab-social-links md:ml-4 md:mt-0 mt-4 ml-0">
            <order-delivery-unit />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-image" :label="!isSmallerScreen ? 'Delivered Files' : ''">
          <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
            <order-delivered-files />
          </div>
        </vs-tab>
        <vs-tab icon-pack="feather" icon="icon-mail" :label="!isSmallerScreen ? 'Messages' : ''">
          <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
            <order-messages />
          </div>
        </vs-tab>
         <vs-tab icon-pack="feather" icon="icon-award" :label="!isSmallerScreen ? 'Review' : ''">
          <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
            <order-review />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>

<script>
import OrderOverview from "./OrderOverview.vue"
import OrderRequirements from "./OrderRequirements.vue"
import OrderDeliveryUnit from "./OrderDeliveryUnit.vue"
import OrderDeliveredFiles from "./OrderDeliveredFiles.vue"
import OrderMessages from "./OrderMessages.vue"
import OrderReview from "./OrderReview.vue"

export default {
  components: {
    OrderOverview,
    OrderRequirements,
    OrderDeliveredFiles,
    OrderDeliveryUnit,
    OrderMessages,
    OrderReview
  },
  data() {
    return {
      isLoading: true,
      order_not_found: false,
    }
  },
  watch: {
    "$route":"fetchOrder"
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    activeTab(){
      return this.$route.query.tab ? +this.$route.query.tab : 0;
    },
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    isOrderStarted(){
      return (this.order.status == 'pending') && (this.order.is_started == 0)
    },
  },
  methods:{
    markStarted() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'Timer will start as soon you start this order.',
        accept: this.proceedStartOrder,
        acceptText: "Accept",
      })
    },
    proceedStartOrder() {
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: [this.order.id]}))
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post('/orders/mark-started',formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
          this.alertSuccess(response.data.success)
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    },
    fetchOrder(){
      this.isLoading = true
      this.$vs.loading({scale: 0.6});
      this.$store.dispatch("orderList/fetchOrder",this.$route.params.orderId)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.isLoading = false
        }else{
          this.order_not_found = true
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    }
  },
  created(){
    this.fetchOrder();
  },
}
</script>

<style lang="scss">
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}

.con-vs-tabs{
    .con-ul-tabs{
        .vs-icon{
            font-size:1.5rem;
        }
    }
}
</style>
