<template>
  <div>
    <vx-card no-shadow >
      <div class="md:flex justify-between items-center">
        <div class="order-details inline-block">
          <h4 class="">Order: #DKO{{order.id}}
          </h4>
           <div class="text-sm" style="color:#ccc;">
              Buyer: <router-link :to="'/users/'+ order.buyer_id" class="cursor-pointer">
                {{order.buyer.first_name }} {{order.buyer.last_name }} (view profile) </router-link> | {{order.created_at | date(true)}}
           </div>
        </div>
        <div class="price-holder">
          <div id="clockdiv">
            <table class="table m-0">
              <tbody class="text-center">
                <template v-if="timer.status && order.status == 'pending'">
                  <tr class="head">
                      <td class="">Days</td>
                      <td class="">Hr</td>
                      <td class="">Min</td>
                      <td class="">Sec</td>
                  </tr>
                  <tr class="timedata">
                      <td> <h4 class="days de">{{ timer.daysSpan }}</h4></td>
                      <td class=""><h4 class="hours de">{{ timer.hoursSpan }}</h4></td>
                      <td class=""><h4 class="minutes de">{{ timer.minutesSpan }}</h4></td>
                      <td class=""><h4 class="seconds"></h4>{{ timer.secondsSpan }}</td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <h4 class="text-danger notify-ex text-center">{{ getOrderMessage }}</h4>
                    <span v-if="order.status == 'completed'" class="text-sm"> {{order.completed_at | date(true) }} </span>
                    <span v-if="order.status == 'refunded'" class="text-sm"> {{order.refunded_at | date(true) }} </span>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <vs-divider class="mb-2"/>
      <div class="flex items-center">
        <div>
          <vs-dropdown vs-trigger-click class="cursor-pointer m-0" left>
            <a class="a-icon " href="#">
              <h3 class="my-0 bold pt-2"><feather-icon icon="AlignJustifyIcon" svgClasses="h-6 w-6" class="mr-2" /></h3>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="getAssignment()">
                <span class="flex items-center">
                  <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Assign&nbsp;Worker</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item v-if="order.status == 'completed'" @click="confirmMarkingPayment()">
                <span class="flex items-center">
                  <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Mark&nbsp;As&nbsp;Paid</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="updateStatus()">
                <span class="flex items-center">
                  <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Update&nbsp;Status</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="initUpdateTimer()">
                <span class="flex items-center">
                  <feather-icon icon="CircleIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Update&nbsp;Timer</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <span  class="font-bold h5 my-0">for {{cart.product.name }} </span>
      </div>
      <vs-divider class="mt-2"/>
      <div>
        <table class="table w-full m-0">
          <tbody class="text-left">
            <template>
                <th>
                  Features
                </th>
                <th  class="px-0">
                  Quantity
                </th>
                <th  class="px-0">
                  Duration
                </th>
                <th v-if="cart.coupon" class="px-0">
                  Discount
                </th>
                <th  class="px-0">
                  Price
                </th>
            </template>

            <template >
              <tr >
                <td >
                  <h6 class="pl-2 py-2"> Package: <span class="text-success">{{orderPackage.name}} - {{order.currency_symbol}}{{orderPackage.price}}</span></h6>
                  <order-feature-list :orderPackage="orderPackage" :addons="addons"/>
                </td>
                <td >
                  1
                </td>
                <td>
                  {{order.delivery_time | delivery_time}}
                </td>
                <td v-if="cart.coupon">
                  {{cart.coupon.percent}}%
                </td>
                <td>
                  {{order.currency_symbol}}{{order.paid_amount}}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <vs-divider/>
      <div >
        <vs-list v-if="this.workers.length">
          <vs-list-header title="Workers Assiged"></vs-list-header>
          <vs-list-item icon-pack="feather" v-for="(worker, wrIndex) in workers" :key="wrIndex" icon="icon-check" :title="worker.first_name+' '+worker.last_name">
            <vs-button radius color="dark" class="inline" type="line" @click="$router.push(`/users/${worker.pivot.worker_id}`).catch(() => {})" icon-pack="feather" icon="icon-eye"></vs-button>
          </vs-list-item>
        </vs-list>
        <p v-else>Order not assigned</p>
      </div>
    </vx-card>

      <vs-popup class="holamundo" @close="close" title="Assign order to worker(s)" :active.sync="popupAssign">
        <vs-divider/>
        <div class="vx-row">
          <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
              <span>
                <v-select label="text" multiple v-model="onAssign.workers" :reduce="item => item.id" placeholder="--select worker(s)--"
                   :closeOnSelect="true" :options="onAssign.workerList" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
              </span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span class="h-auto">
              <vs-button class="ml-auto" @click="submitAssigned()">Assign</vs-button>
            </span>
          </div>
        </div>
      </vs-popup>
      <vs-popup class="holamundo" fullscreen @close="close" title="Update timer" :active.sync="popupUpdateTimer">
        <div class="vx-row">
          <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
            <flat-pickr v-model="updatedTimingDate" :config="{ dateFormat: 'd F Y'}" class="w-full" placeholder="Order expires on" name="expires-time" />
            <span class="text-danger text-sm"  v-show="errors.has('expires-time')">{{ errors.first('expires-time') }}</span>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <span class="h-auto">
              <vs-button class="ml-auto" @click="updateTimer()">Update</vs-button>
            </span>
          </div>
        </div>
      </vs-popup>
      <vs-popup class="holamundo" @close="close" title="Update order status" :active.sync="statusObj.popup">
        <p class="text-sm" >Make sure you are sure of this operation.</p>
        <vs-divider/>
        <div class="vx-row">
        <div class="vx-col sm:w-3/4 mb-sm-0 mb-3 w-full">
            <vs-select autocomplete v-model="statusObj.new" class="select-large w-full">
                <vs-select-item :key="index" :value="status.value" :text="status.text" v-for="(status, index) in statusObj.list" />
            </vs-select>
        </div>
        <div class="vx-col sm:w-1/4 w-full">
            <span class="h-auto">
            <vs-button class="ml-auto" @click="proceedUpdateStatus()">Update</vs-button>
            </span>
        </div>
        </div>
      </vs-popup>
  </div>
</template>
<script>

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'

import OrderFeatureList from "./components/OrderFeatureList.vue"

export default {
  components: {
    flatPickr,
    OrderFeatureList
  },
  data() {
    return {
      timer: {
        daysSpan: "",
        hoursSpan: "",
        minutesSpan: "",
        secondsSpan: "",
        status: false,
      },
      deadline: "",
      interval: "",
      popupAssign: false,
      onAssign:{
        workers: [],
        workerList:[],
      },
      popupUpdateTimer:false,
      updatedTimingDate: null,
      statusObj: {
        list: [
            {value: 'pending',text: 'Pending'},
            {value: 'delivered',text: 'Delivered'},
            {value: 'completed',text: 'Completed'},
            {value: 'refunded',text: 'Refunded'},
            {value: 'draft',text: 'Draft'}
        ],
        new: "",
        popup: false,
      }
    }
  },
  watch: {
    order(){
      this.timerIgniter()
    }
  },
  computed: {
    order(){
      return this.$store.state.orderList.order
    },
    cart(){
      return this.order.cart
    },
    orderPackage(){
      return this.cart.package
    },
    attributes(){
      return this.cart.attributes
    },
    addons(){
      return this.cart.addons
    },
    workers(){
        return this.order.workers
    },
    getOrderMessage() {
      if(this.order.status == 'completed'){
        return 'Order Completed!'
      }else if(this.order.status == 'delivered'){
        return 'Order has been delivered!'
      }else if(this.order.status == 'refunded'){
        return 'Order was refunded!'
      }else if(this.order.status == 'draft'){
        return 'Saved as Draft!'
      }else if(!this.order.started_at){
        return 'Order Not Started!'
      }else {
        return 'Time has expired!'
      }
    },
  },
  methods: {
    confirmMarkingPayment(){
      if(this.order.is_workers_paid){ this.alertError("Already Marked as Paid"); return}
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to mark this order as paid',
        accept: this.proceedMarkAsPaid,
        acceptText: "Yes, Proceed",
      })
    },
    proceedMarkAsPaid(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: [this.order.id]}))
      this.resquestProcessor(formData,'mark-as-paid')
    },
    initUpdateTimer(){
      this.popupUpdateTimer = true;
      this.updatedTimingDate  = new Date(this.order.started_at)
    },
    updateTimer(){
      if(!this.updatedTimingDate){ this.alertError("Selected Valid Date"); return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({date: this.updatedTimingDate}))
      this.resquestProcessor(formData, `${this.order.id}/update-timer`)
    },
    updateStatus(){
      this.statusObj.new = this.order.status
      this.statusObj.popup = true
    },
    proceedUpdateStatus(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: [this.order.id], status: this.statusObj.new}))
      this.resquestProcessor(formData,'update-status')
    },
    getAssignment(){
      this.onAssign.workers = this.workers.map((w) => w.pivot.worker_id);
      if(this.onAssign.workerList.length){this.popupAssign = true; return}
      this.$http.get("/users/workers")
      .then(response => {
        if(response.data.success){
          this.onAssign.workerList = response.data.workers.map(emp => {
            return { id: emp.id, text: "Worker_" + emp.id + " "+ emp.username }})
          this.popupAssign = true;
        }
      }).catch(error => { console.log(error) })
    },
    submitAssigned(){
      if(!this.onAssign.workers || !this.onAssign.workers.length){ this.alertError("Selected A worker"); return}
      const formData = new FormData()
      formData.append('data', JSON.stringify({orders: [this.order.id], workers: this.onAssign.workers}))
      this.resquestProcessor(formData,'assign-workers')
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/orders/${action}`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.initializeAll();
          this.$store.dispatch("orderList/fetchOrder",this.order.id)
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    timerCount(){
        var now = new Date().getTime()
        var distance = this.deadline - now
        if( distance > 0){
            this.calcTime(distance)
        }else{
          this.timer.status = false
          clearInterval(this.interval)
          return
        }
    },
    calcTime(dist){
      // Time calculations for days, hours, minutes and seconds
        this.timer.daysSpan = Math.floor(dist / (1000 * 60 * 60 * 24))
        this.timer.hoursSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
        this.timer.minutesSpan = this.toSlice(Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)))
        this.timer.secondsSpan = this.toSlice(Math.floor((dist % (1000 * 60)) / 1000))
    },
    toSlice(t){
      return ('0' + t).slice(-2)
    },
    timerIgniter(){
      if(this.order.status === "pending" && this.order.is_started  && this.order.started_at){
        let timeToDelivery = new Date(this.order.started_at).getTime() + (parseInt(this.order.delivery_time)*60*60*1000)
        this.deadline = new Date().setTime(timeToDelivery)
        this.timer.status = true
        this.timerCount()
        this.interval = setInterval(() => {
          this.timerCount()
        }, 1000)
      }
    },
    initializeAll(){
      this.popupAssign = false;
      this.popupUpdateTimer = false;
      this.statusObj.popup = false;
      this.onAssign.workers = []
      this.$validator.reset()
    },
    close(){
      this.initializeAll();
    }
  },
  mounted(){
    this.timerIgniter()
  },
  beforeDestroy(){
    clearInterval(this.interval)
  }
}

</script>

<style type="text/css">
  #vs3__listbox{
    z-index: 999999999;
  }
</style>
<style type="text/css" scoped>

    body,.text-sm{
        font-size: 12px;
    }

    sub{font-size: 12px;}

    #clockdiv{
        display: inline-block;
        float:left;
        margin-left:5px;
    }
    #clockdiv table .timedata td{
      background-color: #f8f8f8;
    }
    #clockdiv table .timedata td h4 {
        margin-bottom: 0px;
        color: #008b9c;
        font-size: 12px;
    }

    #clockdiv .notify-ex{
        font-size: 12px;
    }
    #clockdiv .table th, #clockdiv .table td{
        padding:3px 5px;
    }
    .price-holder{
        float:right;
        display:inline-block;
    }

    @media(max-width: 580px){
      .order-details{
        display: block;
        margin-bottom: 1.5em;

      }
      .price-holder{
        display:block;
        margin-bottom: 1.5em;
      }
    }
    .price-holder:first-child{
        font-size: 34px;
        color:#444;
        display: inline-block;
    }
    table thead tr{
        border-top:2px solid #ededed !important;
        background: #c2c6dc30 !important;
    }

    .table-responsive {
      display: block !important;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }

      @media (max-width: 767.98px) {
        .table-responsive-md {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-md > .table-bordered {
          border: 0;
        }
      }

      @media (max-width: 991.98px) {
        .table-responsive-lg {
          display: block;
          width: 100%;
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
        .table-responsive-lg > .table-bordered {
          border: 0;
        }
      }
    .icon-config{
      width:12px !important;
      height: 12px !important;
    }

</style>
