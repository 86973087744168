
<template>
  <div id="upload-alphabet">
    <div>
        <vue-dropzone id="dropzone" ref="fileUploadDrop" :use-custom-slot="true" :options="dropzoneOptions">
            <div class="dz-message shadow text-center my-0 px-3 py-8 vx-card rounded needsclick" style="border: 1px dashed #aaa;">
                <i class="h1 text-primary mdi mdi-plus"></i>
                <h4>Upload Files or Drop files</h4>
                <span class="text-primary font-13">
                    <em>Maximum of 10 files</em>
                </span>
            </div>
        </vue-dropzone>
    </div>
    <div class="flex flex-wrap justify-end items-center mt-5">
        <vs-button type="filled" @click.prevent="emitAttachment()" class="mr-6">Upload</vs-button>
    </div>
</div>
</template>

<script>
export default {
 name: "upload-attachment",
  data() {
    return {
      dropzoneOptions: {
        url: "http//null",
        thumbnailWidth: 550,
        maxFilesize:200,
        maxFiles:10,
        autoProcessQueue:false,
        acceptedFiles: "image/*,.ai,.eps,.psd,.zip,.pdf,application/zip",
        headers: {
            "My-Awesome-Header": "header value",
        },
        previewTemplate: this.dropzoneTemplate(),
      },
    }
  },
  methods: {
    emitAttachment() {
        if(!this.collectFiles().length){ this.alertError("Please a file is required"); return;}
        this.$emit('pushAddedFiles',this.collectFiles());
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.fileUploadDrop){
        arrafile = [...this.$refs.fileUploadDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    removeFiles() {
      this.$refs.fileUploadDrop.removeAllFiles();
    },
  }
}

</script>

