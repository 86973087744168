<template>
  <div>
    <div>
      <vx-card no-shadow>
        <VuePerfectScrollbar class="message-content-scroll-area border border-solid d-theme-border-grey-light" :settings="settings" ref="messageLogPS" :key="$vs.rtl">
          <div class="message-wrapper" ref="messageLog">
            <ul class="list my-2">
              <li class="list__item" v-for="(message, index) in messages" :key="index">
                <div v-if="isSentUser(message.who)" style="padding-left:30%;"  class="w-full">
                  <span class="p-3 inline-flex float-right rounded" style="background-color:#f8f8f8;">
                    <div>
                      <p class="text-right">{{message.message}}</p>
                      <file-display v-if="message.files && message.files.length" :list="message.files" />
                      <div class="text-right">
                        <span class="opacity-50 text-sm">{{message.created_at | date_ago}}</span>
                        <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initEditMessage(message)">Edit</a>
                        <a class="ml-2 text-sm text-danger" href="javascrip:void(0)" @click.prevent="confirmDelete(message.id)">Delete</a>
                        <div v-if="message.replyer_id" class="pr-4 pt-1">
                          <vs-divider/>
                          <h6 class="font-semibold text-sm italic">Client Replied</h6>
                          <p class="mb-1"> {{message.reply}} </p>
                          <small class="opacity-50">Replied {{message.replied_at | date_ago}}</small>
                        </div>
                      </div>
                    </div>
                    <div class="pt-2">
                      <feather-icon icon="ArrowUpIcon" class="w-5 h-5 text-warning ml-1"></feather-icon>
                    </div>
                  </span>
                </div>
                <div v-else class="w-full" style="padding-right:30%;">
                  <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
                    <div class="pt-2">
                      <feather-icon icon="ArrowDownIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                    </div>
                    <div>
                      <p>{{message.message}}</p>
                      <file-display v-if="message.files && message.files.length" :list="message.files" />
                      <div class="text-sm">
                        <a v-if="!message.replyer_id" class="mr-2 pt-2 inline-block text-success" href="javascrip:void(0)" @click.prevent="initReply(message.id)">Reply</a>
                         <a class=" mr-2 font-weight-bold text-danger" href="javascrip:void(0)" @click.prevent="confirmDelete(message.id)">Delete</a>
                          {{message.created_at | date_ago}}

                        <div v-if="message.replyer_id" class="pl-4 pt-1">
                          <vs-divider/>
                          <h6 class="font-semibold text-sm italic">You Replied</h6>
                          <p class="mb-1"> {{message.reply}} </p>
                          <small class="opacity-50">Replied {{message.replied_at | date_ago}}</small>
                        </div>
                      </div>
                    </div>
                 </span>
                </div>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>
        <div class="mt-5 flex items-baseline p-2 bg-white">
            <vs-button radius color="dark" @click.prevent="popupAttachment = true"  class="inline mr-2" type="line" icon-pack="feather" icon="icon-paperclip"></vs-button>
            <vs-input class="flex-1" placeholder="Type Your Message" v-model="newMessage" @keyup.enter="createMessage" />
            <vs-button class="bg-primary-gradient ml-2 flex items-center" type="filled" @click="createMessage"> <feather-icon v-if="isSmallerScreen" icon="SendIcon" class="" svgClasses="pt-1 w-4 h-4"> </feather-icon> {{isSmallerScreen ? '': 'Send'}}</vs-button>
        </div>
      </vx-card>

      <vs-popup class="holamundo" @close="close" title="Reply Message" :active.sync="popupReplyMessage">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onReply.reply" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateReply" @click="sendReply()" size="small">Send Reply</vs-button>
      </vs-popup>

      <vs-popup class="holamundo" @close="close" title="Update Message" :active.sync="popupEditMessage">
        <div class="mt-1">
          <vs-textarea label="Write your comment" v-model="onEdit.message" rows="1" />
        </div>
          <vs-button class="ml-auto mt-2" :disabled="!validateEdit" @click="updateMessage()" size="small">Update</vs-button>
      </vs-popup>

      <vs-popup
        @close="close"
        title="Add Files"
        :active.sync="popupAttachment">
         <upload-attachment @pushAddedFiles="sendAttachment($event)" ref="fileUploaderView" />
      </vs-popup>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import UploadAttachment from './components/UploadAttachment.vue'

export default {
  components: {
    VuePerfectScrollbar,
    UploadAttachment
  },
  data() {
    return {
      awaitingDelete: null,
      newMessage: "",
      popupReplyMessage:false,
      onReply: {
        id: null,
        reply: "",
      },
      settings: {
        maxScrollbarLength : 60,
        wheelSpeed         : 0.70,
      },
      popupEditMessage:false,
      onEdit: {},
      popupAttachment: false,
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
    activeUser() {
      return this.$store.state.AppActiveUser
    },
    validateReply() {
      return this.onReply.reply != ""
    },
    validateEdit() {
      return this.onEdit.message != ""
    },
    validateMessage() {
      return this.newMessage != ""
    },
    order(){
      return this.$store.state.orderList.order
    },
    messages(){
      return this.order.messages ? this.order.messages : []
    }
  },
  methods: {
    isSentUser(who){
      return  who == 'admin' || who == 'worker'
    },
    sendAttachment(files){
      if(files.length < 1){return}
      let formData = new FormData()
      files.forEach((item, index) => formData.append('file_'+index, item))
      this.resquestProcessor(formData,`${this.order.id}/attachments`)
    },
    updateMessage(){
      const formData = new FormData()
      formData.append('data', JSON.stringify({message: this.onEdit.message}))
      this.resquestProcessor(formData,`${this.onEdit.id}/update`, 'UPDATE')
    },
    createMessage(){
      this.$validator.validateAll().then(result => {
        if (result) {
          let formData = new FormData()
          formData.append('data', JSON.stringify({"message": this.newMessage}))
          this.resquestProcessor(formData,`${this.order.id}/create`)
        }
      })
    },
    sendReply(){
      if(this.onReply.id < 1){return}
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply }))
      this.resquestProcessor(formData,`${this.onReply.id}/reply`, 'UPDATE')
    },
    resquestProcessor(formData, action, ftn='ADD'){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/order-messages/${action}`,formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.initializeAll()
          this.$store.commit(`orderList/${ftn}_MESSAGE`, response.data.message)
          if(ftn == 'ADD'){this.scrollMessageAreaUp();}
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    initEditMessage(message){
      this.onEdit = {
        id: message.id,
        message: message.message,
      }
      this.popupEditMessage = true
    },

    initReply(msgId){
      this.onReply.id = msgId
      this.popupReplyMessage = true
    },
    confirmDelete(msgId) {
      this.awaitingDelete = msgId
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: 'Are you sure?',
        text: 'You are about to delete a messsage.' ,
        accept: this.deleteMessage,
        acceptText: "Proceed",
      })
    },
    deleteMessage() {
      if(this.awaitingDelete < 1){return}
      this.$http.delete(`/order-messages/${this.awaitingDelete}/delete`)
      .then(response => {
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("orderList/DELETE_MESSAGE",response.data.messageId)
        }else{
          this.alertError(response.data.error)
        }
      }).catch(error => { console.log(error) })
    },
    scrollMessageAreaUp(){
      setTimeout(()=>{
        this.$refs.messageLogPS.$el.scrollTop = this.$refs.messageLog.scrollHeight
      },1000)
    },
    initializeAll(){
      this.awaitingDelete = null
      this.popupReplyMessage = false
      this.onReply = {
        id: null,
        reply: "",
      }
      this.popupEditMessage = false
      this.onEdit = {}
      this.newMessage = ""
      this.popupAttachment =  false
      this.$validator.reset()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
  mounted(){
    this.scrollMessageAreaUp();
  }
}
</script>

<style lang="scss" scoped>
  .message-content-scroll-area {
      position: relative;
      margin: auto;
      width: 100%;
      height: 270px;
  }
</style>
