
<template>
    <ul class="list">
        <template  v-for="(attribute, index) in orderPackage.attributes">
            <li class="list__item" :key="index" v-if="attribute.support">
                <feather-icon icon="CheckIcon" :svgClasses="'icon-config'" class="w-4 text-success h-4 mr-1"></feather-icon>
                <span class="text-sm">
                  <span> {{attribute.name | truncate(30)}}</span>
                </span>
            </li>
        </template>

        <li class="list__item" v-if="addons.length" > <span class="font-bold">Addons:</span></li>
        <li class="list__item" v-for="(addon,  index) in addons" :key="189+index">
            <feather-icon icon="CheckIcon" :svgClasses="'icon-config'" class="w-4 text-success h-4 mr-1"></feather-icon>
            <span class="text-sm">
              <span>{{addon.name | truncate(30)}}</span>
              <span> ({{orderPackage.currency_symbol || '$'}}{{addon.charge}})</span>
            </span>
        </li>
    </ul>
</template>

<script>
export default {
    name: "order-feature-list",
    props: {
        orderPackage: {
            type: Object,
            required: true,
        },
        addons: {
            type: Array,
            required: true,
        },
    },
}
</script>
