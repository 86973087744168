<template>
  <div v-if="showDelivery">
    <vx-card no-shadow class="mb-4">
      <div class="action-field flex justify-end mb-1">
        <vs-dropdown vs-trigger-click class="cursor-pointer m-0">
          <div class="p-1 active-wrap rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium" >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="initUpdateMessage()">
              <span class="flex items-center">
                <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Edit</span>
              </span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="deleteDelivery()">
              <span class="flex items-center">
                <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>Delete</span>
              </span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>
      <div class="">
        <div class="mb-4">
          <div v-html="delivery.message"> </div>
          <p class="text-sm" style="color:#ccc;">Date: {{delivery.created_at | date_ago}}</p>
        </div>
      </div>
      <div>
        <delivery-files v-if="delivery.files && delivery.files.length" :files="delivery.files" />
      </div>
      <div class="vx-row justify-center">
        <div class="vx-col w-full mt-3">
          <ul class="list my-2">
            <li class="list__item" v-for="(comment, index) in delivery.comments" :key="index">
              <div class="w-full">
                <span class="px-3 py-2 inline-flex rounded" style="background-color:#f8f8f8;">
                  <div class="pt-2">
                    <feather-icon icon="ArrowDownIcon" class="w-5 h-5 text-warning mr-1"></feather-icon>
                  </div>
                  <div>
                    <p>{{comment.text}}</p>
                    <div class="text-right">
                      <span class="opacity-50 text-sm">{{comment.created_at | date_ago}}</span>
                      <a  v-if="!comment.replyer_id" class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initReplyComment(comment.id)">Reply </a>
                      <div v-if="comment.replyer_id" class="pr-4 pt-4">
                        <h6 class="font-semibold text-sm italic">Response</h6>
                        <p class="mb-1"> {{comment.reply}} </p>
                        <small class="opacity-50">Replied {{comment.reply_date | date_ago}}</small>
                        <a class="ml-2 text-sm" href="javascrip:void(0)" @click.prevent="initReplyEdit(comment)">Edit</a>
                      </div>
                    </div>
                  </div>
               </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </vx-card>

    <vs-popup class="holamundo" @close="close" :title="onEditMode? 'Edit Reply':'Reply Comment'" :active.sync="popupCommentReply">
      <div class="mt-1">
        <vs-textarea label="Write your reply" v-model="onReply.reply" rows="1" />
      </div>
      <vs-button class="ml-auto mt-1" :disabled="!validateReply" @click="onEditMode? updateReply() : replyComment()" size="small">Send</vs-button>
    </vs-popup>
    <vs-popup class="holamundo" @close="close" title="Update delivery message" :active.sync="popupUpdateMsg">
      <div class="mt-1">
        <vs-textarea label="Write delivery message" v-model="newMessage" rows="1" />
      </div>
      <vs-button class="ml-auto mt-1" :disabled="!validateMessage" @click="updateMessage()" size="small">Update</vs-button>
    </vs-popup>

  </div>
</template>

<script>
import DeliveryFiles from "./DeliveryFiles.vue"

export default {
  components:{
    DeliveryFiles
  },
   props: {
    delivery : { type: Object,  required: true, default: {} }
  },
  data() {
    return {
      showDelivery: false,
      popupCommentReply: false,
      popupUpdateMsg: false,
      newMessage:"",
      onReply: {
        id: null,
        reply: ""
      },
      onEditMode: false
    }
  },
  computed: {
    validateReply() {
      return this.onReply.reply != ""
    },
    validateMessage() {
      return this.newMessage != ""
    },
  },
  created(){
    if(this.delivery.id){
      this.showDelivery = true
    }
  },
  methods: {
    initUpdateMessage(){
      this.newMessage = this.delivery.message
      this.popupUpdateMsg = true
    },
    initReplyComment(commentId){
      if (!commentId) { return;}
      this.onReply.id = commentId
      this.popupCommentReply = true
    },
    initReplyEdit(comment){
      this.onEditMode = true;
      this.popupCommentReply = true
      this.onReply = {
        id: comment.id,
        reply: comment.reply
      }
    },
    updateReply(){
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply}))
      this.resquestProcessor(formData,`delivery-comments/${this.onReply.id}/update-reply`)
    },
    replyComment(){
      let formData = new FormData()
      formData.append('data', JSON.stringify({reply: this.onReply.reply}))
      this.resquestProcessor(formData,`delivery-comments/${this.onReply.id}/reply`)
    },
    updateMessage(){
      let formData = new FormData()
      formData.append('data', JSON.stringify({message: this.newMessage}))
      this.resquestProcessor(formData,`order-deliveries/${this.delivery.id}/update`)
    },
    resquestProcessor(formData, action){
      this.$vs.loading({color: "#444", type: "sound"})
      this.$http.post(`/${action}`, formData)
      .then(response => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("orderList/UPDATE_DELIVERY", response.data.delivery);
          this.initializeAll()
        }else if(response.data.errors){
          response.data.errors.forEach((error) => { this.alertError(error)})
        }else{
          this.alertError(response.data.error)
        }
     }).catch((error)=>{console.log( error) })
    },
    deleteDelivery(){
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Are you sure?',
        text: 'You want to delete this delivery!',
        accept: this.acceptDelete
      })
    },
    acceptDelete(){
      if(!this.delivery.id){return}
      this.$vs.loading({color: "#444", type:'sound'})
      this.$http.delete(`/order-deliveries/${this.delivery.id}/delete`)
      .then((response) => {
        this.$vs.loading.close()
        if(response.data.success){
          this.alertSuccess(response.data.success)
          this.$store.commit("orderList/DELETE_DELIVERY", response.data.deliveryId)
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error)=>{console.log(error)})
    },
    initializeAll(){
      this.popupCommentReply = false
      this.popupUpdateMsg = false
      this.newMessage = ""
      this.onReply = {
        id: null,
        reply: ""
      }
      this.onEditMode = false
      this.$validator.reset()
    },
    close(){
      this.$vs.notify({
      title:'Notice',
      text:"You cancel an operation",
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'bottom-right'})
      this.initializeAll()
    },
  },
}
</script>

<style lang="scss">
.action-field{
  margin-top: -10px;
}
.action-field:hover{
  button{
    .active-wrap{
      transition: all 0.3s ease-in-out;
      -webkit-box-shadow: 0 7px 7px 5px rgba(0,0,0,.08);
      box-shadow: 0 7px 7px 5px rgba(0,0,0,.08);
    }

  }
}

</style>
